import lodash from 'lodash';
import { logout } from "../../lib/utils/auth_utils"
import {
  useRecoilState
} from 'recoil';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../ui/avatar"
import { Button } from "../ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"
import { LoginDialog } from "../LoginDialog";
import { userState, userSubscriptionState, subscriptionPlansState } from "../../store/store";

export function UserNav() {
  const [user, setUserState] = useRecoilState(userState);
  const [userSubscription, setUserSubscriptionState] = useRecoilState(userSubscriptionState);
  const [subscriptionPlans] = useRecoilState(subscriptionPlansState);

  if (!user) {
    return <LoginDialog btnLabel="Sign In" />;
  }

  const currentPlanId = lodash.get(userSubscription, 'plan_id', null);
  const currentPlan = subscriptionPlans.find(plan => plan.id === currentPlanId);
  const creditsUsed = lodash.get(userSubscription, 'credits_used', 0);
  const creditsLimit = lodash.get(userSubscription, 'credits', 0);

  const logoutUser = () => {
    logout();
    setUserState(null);
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage src={lodash.get(user, ['profile_image']) ? lodash.get(user, ['profile_image']) : "https://github.com/shadcn.png"} alt="@shadcn" />
            <AvatarFallback></AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-64 min-w-[200px]" align="end" forceMount> {/* Set min width to 200px */}
        {user ? <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <div className='mb-2'>
              <p className="text-sm font-medium leading-none mb-2">{lodash.get(user, 'username')}</p>
              <p className="text-xs leading-none text-muted-foreground">
                {lodash.get(user, 'email')}
              </p>
            </div>
            <div className="text-xs leading-none text-muted-foreground">
              {currentPlan ? (
                <div className="flex flex-col bg-muted rounded p-2">
                  <p className='text-sm font-semibold'>Current Plan: <span className='text-primary'>{currentPlan.name}</span></p>
                  <p className='text-sm'>Credits Used: <span className='text-primary'>{creditsUsed}</span> / <span className='text-primary'>{creditsLimit}</span></p>
                </div>
              ) : (
                <p>No active subscription.</p>
              )}
            </div>
          </div>
        </DropdownMenuLabel> : null}
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
        </DropdownMenuGroup>
        <DropdownMenuItem className='cursor-pointer' onClick={logoutUser}>
          Log out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
