import React, { useEffect, useState } from 'react';
import { streamFile } from '../../../services/api';
import LinkPreview from './LinkPreview';
import PDFPreview from './PDFPreview';
import UploadPreview from './UploadPreview';

const PreviewContent = ({ knowledgeBase }) => {
    const { type, link, content_metadata } = knowledgeBase; // Destructure content_metadata
    const [fileUrl, setFileUrl] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        if (type === 'upload') {
            const fileKey = link.split('/').pop();
            // Uncomment and implement the streamFile function to get the file URL
            // streamFile(fileKey)
            //     .then(response => {
            //         const url = URL.createObjectURL(new Blob([response.data]));
            //         setFileUrl(url);
            //     })
            //     .catch(error => {
            //         console.error('Error streaming file:', error);
            //     });
        }
    }, [type, link]);

    if (type === 'link') {
        return <LinkPreview knowledgeBase={knowledgeBase} />; // Pass transcriptions
    }

    if (type === 'upload') {
        if (link.includes('.pdf')) {
            return (
                <iframe
                    src={link} // Direct link to the PDF file
                    width="100%"
                    height="100%"
                    title="PDF Preview"
                />
            );
        }
        return <UploadPreview fileUrl={fileUrl} />;
    }

    return <p>No preview available for this type.</p>;
};

export default PreviewContent;
