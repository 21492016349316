import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { validateCheckoutSession } from '../services/api'; // Import the validation API
import { logError, logDebug } from '../services/logging_utils'; // Import logging utilities
import { CheckCircle, Loader2 } from 'lucide-react'; // Import icons for success message and loader
import { useSetRecoilState } from 'recoil';
import { userState } from '../store/store'; // Import user state from store
import lodash from 'lodash'; // Import lodash

const SubscriptionSuccess = () => {
    const location = useLocation();
    const history = useHistory();
    const setUser = useSetRecoilState(userState); // Set user state
    const [message, setMessage] = useState('Validating your payment, please give us a few moments...');
    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const sessionId = queryParams.get('sid');
        const userId = queryParams.get('uid');
        const planId = queryParams.get('pid');
        const provider = queryParams.get('provider');

        const validatePayment = async () => {
            try {
                const response = await validateCheckoutSession({
                    session_id: sessionId,
                    user_id: userId,
                    plan_id: planId,
                    provider: provider
                });
                const updatedUser = lodash.get(response, 'data.user'); // Use lodash to get the updated user from response
                if (updatedUser) {
                    logDebug('Payment validation successful'); // Log success
                    setUser(updatedUser); // Update user state with the returned user
                    setMessage('Payment validation successful! Redirecting to your dashboard in 5 seconds...');
                    setRedirecting(true);
                    setTimeout(() => {
                        history.push('/dashboard/home'); // Redirect to dashboard
                    }, 5000);
                } else {
                    logError('Payment validation failed'); // Log failure
                    setMessage('Payment validation failed. Please try again later.');
                }
            } catch (error) {
                logError('Error validating payment:', error); // Use logger for error
                setMessage('An error occurred while validating your payment. Please try again later.');
            }
        };

        validatePayment();
    }, [location, history, setUser]);

    return (
        <div className="w-screen h-screen flex flex-col items-center justify-center text-primary">
            {redirecting ? (
                <div className="flex flex-col items-center justify-center text-primary">
                    <CheckCircle className="text-green-500" />
                    <Loader2 className="animate-spin text-primary" />
                    <p className="text-primary text-lg font-semibold">{message}</p>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center text-primary">
                    <Loader2 className="animate-spin text-primary" />
                    <p className="text-primary text-lg font-semibold">{message}</p>
                </div>
            )}
        </div>
    ); // Loading message
};

export default SubscriptionSuccess;
