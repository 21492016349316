import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import { X, Loader } from 'lucide-react'; // Import the Upload and X icons from Lucide

export const UploadComponent = ({ onChange, disabled, loading, error, supportedExtensions }) => { // Added supportedExtensions prop
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        if (onChange) onChange(selectedFile); // Call onChange if provided
    };

    const handleDrop = useCallback((event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
            setMessage('');
        }
    }, []);

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDropAreaClick = () => {
        document.getElementById('fileInput').click(); // Trigger the file input click
    };

    const handleRemoveFile = () => {
        setFile(null); // Clear the selected file
    };

    return (
        <div className="upload-container">
            <div
                className="drop-area hover:bg-muted bg-background" // Added hover effect
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={handleDropAreaClick} // Add click handler to drop area
            >
                {file ? (
                    <div className="flex flex-col justify-between w-full">
                        <p className="text-md text-left">{file.name}</p> {/* Show file name, left aligned */}
                        <p className="text-sm text-left">Size: {(file.size / (1024 * 1024)).toFixed(2)} MB</p> {/* Show file size in MB, left aligned */}
                        <div className="flex justify-start items-center"> {/* Changed justify to start for left alignment */}
                            <button onClick={handleRemoveFile} className="text-red-500 flex items-center">
                                <span className="ml-1">Remove</span> {/* Add remove label */}
                                <X className="ml-2 h-4 w-4" />
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col'>
                        <p>Drag and drop your file here, or click the area to select</p>
                        <p className="file-format-message">
                            Accepted formats: {supportedExtensions.join(', ')} {/* Display supported formats */}
                        </p>
                    </div>
                )}
            </div>
            <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="fileInput"
                disabled={disabled || loading} // Disable input based on the prop and upload state
            />
            {loading && (
                <div className="loader text-left font-bold text-blue-900 flex items-start justify-start ">
                    <Loader className=' w-4 h-4 mr-2 animate-spin' />
                    <p className='text-sm'>Uploading...</p>
                </div>
            )} {/* Show uploading progress */}
            {error && <p className="text-red-500 text-sm italic">{error}</p>} {/* Show error message */}
        </div>
    );
};

UploadComponent.propTypes = {
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.string,
    supportedExtensions: PropTypes.arrayOf(PropTypes.string), // Define prop type for supportedExtensions
};

UploadComponent.defaultProps = {
    onChange: null,
    disabled: false,
    loading: false,
    error: '',
    supportedExtensions: ['.jpg', '.png', '.pdf', '.docx', '.txt'], // Default supported extensions
};
