import React, { useState } from 'react';
import lodash from 'lodash';
import { cn } from '../../../../src/lib/utils';
import { KnowledgeBaseStatusBadge } from '../../../components/KnowledgeBaseStatusBadge';
import { Separator } from '../../../components/ui/separator'; // Import the Separator component
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../../components/ui/tabs'; // Import Tabs components
import KnowledgeBaseCard from '../../../components/KnowledgeBaseCard'; // Import the new KnowledgeBaseCard component

const KnowledgeBaseList = ({ knowledgeBases, selectedKnowledgeBase, onSelectKnowledgeBase }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('All');

    // Group knowledge bases by type
    const groupedKnowledgeBases = knowledgeBases.reduce((acc, kb) => {
        const type = kb.type || 'Other'; // Default to 'Other' if type is not defined
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(kb);
        return acc;
    }, {});

    // Filter knowledge bases based on search term and selected filter type
    const filteredKnowledgeBases = Object.entries(groupedKnowledgeBases).map(([type, bases]) => {
        const filteredBases = bases.filter(kb =>
            (filterType === 'All' || kb.type === filterType) && // Filter by type
            (kb.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (kb.description && kb.description.toLowerCase().includes(searchTerm.toLowerCase())))
        );

        return { type, bases: filteredBases };
    }).filter(group => group.bases.length > 0); // Remove empty groups

    return (
        <div className="flex flex-col p-2 overflow-y-auto max-h-[calc(100vh-144px)] h-full"> {/* Added vertical scroll */}
            {filteredKnowledgeBases.map(({ type, bases }) => (
                <div key={type} className="my-2">
                    <h2 className="text-lg font-bold capitalize text-primary text-left ml-2">{type}</h2>
                    {bases.map((kb) => (
                        <KnowledgeBaseCard
                            key={kb.id}
                            knowledgeBase={kb}
                            onSelect={onSelectKnowledgeBase}
                            selected={selectedKnowledgeBase?.id === kb.id} // Pass selected prop
                        />
                    ))}
                    <Separator />
                </div>
            ))}
        </div>
    );
};

export default KnowledgeBaseList;
