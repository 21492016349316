import React from 'react';
import { Button } from '../components/ui/button'; // Import the Button component
import { useHistory } from 'react-router-dom';

const CancelSubscription = () => {
    const history = useHistory();

    const handleRedirect = () => {
        history.push('/dashboard/billing'); // Redirect to the billing page
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <h1 className="text-xl font-bold">Payment Cancelled</h1>
            <p className="mt-4">Your payment has failed. Please try again.</p>
            <Button className="mt-6" onClick={handleRedirect}>Go to Billing</Button>
        </div>
    );
};

export default CancelSubscription;
