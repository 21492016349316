import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory for navigation
import lodash from 'lodash';
import { ArrowRight } from 'lucide-react'; // Import the arrow icon
import { Separator } from '../../components/ui/separator';
import { Button } from '../../components/ui/button';
import { KnowledgeBaseForm } from '../../components/KnowledgeBaseForm';
import { getKnowledgeBases } from '../../services/api';
import { logError, logDebug } from '../../services/logging_utils';
import RecentKnowledgeBaseList from '../../components/RecentKnowledgeBaseList'; // Import the new RecentKnowledgeBaseList component
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../components/ui/tabs'; // Import the Tabs components
import ChatInterface from '../../views/dashboard/products/components/ChatInterface'; // Import the ChatInterface component
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '../../components/ui/accordion'; // Import Accordion components

export default function HomeView() {
    const history = useHistory(); // Initialize history
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState(null); // State to track selected knowledge base
    const [leftActiveTab, setLeftActiveTab] = useState("create"); // State to track active tab for left side
    const [rightActiveTab, setRightActiveTab] = useState("recent"); // State to track active tab for right side
    const userId = "user-id-placeholder"; // Replace with actual user ID
    const knowledgeBaseId = "knowledge-base-id-placeholder"; // Replace with actual knowledge base ID

    useEffect(() => {
        const fetchKnowledgeBases = async () => {
            setLoading(true);
            try {
                const response = await getKnowledgeBases();
                setKnowledgeBases(lodash.get(response, 'data.data', []));
                logDebug("Knowledge bases fetched successfully.");
            } catch (error) {
                logError("Error fetching knowledge bases:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchKnowledgeBases();
    }, []);

    const onUpdate = (kb) => {
        history.push(`/dashboard/knowledge-base?knowledge_base=${kb.id}`);
    }

    return (
        <div className="flex w-full flex-col px-2 py-2 text-left bg-background overflow-y-auto" style={{ height: 'calc(100vh - 80px)' }} >
            <div className="flex flex-col bg-muted rounded-lg p-4 overflow-y-auto" style={{ height: '100%' }}>
                <div className="flex flex-row w-full gap-4" style={{ height: 'calc(100vh - 132px)' }}>
                    <div className="flex flex-col w-8/12 pr-4 bg-background p-4 rounded-lg">
                        <Tabs defaultValue={leftActiveTab} className="w-full h-full">
                            <TabsList>
                                <TabsTrigger active={leftActiveTab === "create"} value="create" onClick={() => setLeftActiveTab("create")}>Create Knowledge Base</TabsTrigger>
                                <TabsTrigger active={leftActiveTab === "chat"} value="chat" onClick={() => setLeftActiveTab("chat")}>Chat</TabsTrigger>
                            </TabsList>
                            <TabsContent value="create" className={leftActiveTab === "create" ? "visible" : "hidden"} style={{ height: 'calc(100% - 40px)' }}>
                                <Separator className="my-2" />
                                <KnowledgeBaseForm onUpdate={onUpdate} />
                            </TabsContent>
                            <TabsContent value="chat" className={leftActiveTab === "chat" ? "visible" : "hidden"} style={{ height: 'calc(100% - 40px)' }}>
                                <Separator className="mt-1" />
                                <ChatInterface scope="user_chat" userId={userId} knowledgeBaseId={knowledgeBaseId} />
                            </TabsContent>
                        </Tabs>
                    </div>
                    <div className="flex flex-col w-4/12 pl-4 bg-background p-4 rounded-lg text-left overflow-y-auto">
                        <Tabs defaultValue={rightActiveTab} className="w-full h-full">
                            <TabsList>
                                <TabsTrigger active={rightActiveTab === "recent"} value="recent" onClick={() => setRightActiveTab("recent")}>Recent Knowledge Bases</TabsTrigger>
                                <TabsTrigger active={rightActiveTab === "help"} value="help" onClick={() => setRightActiveTab("help")}>Help</TabsTrigger>
                            </TabsList>
                            <TabsContent value="recent" className={rightActiveTab === "recent" ? "visible" : "hidden"}>
                                <Separator className="mt-1" />
                                <div className="flex items-center justify-between">
                                    <p className='text-xl font-semibold text-primary'>Recent Knowledge Bases</p>
                                    <Button
                                        variant="link"
                                        className="text-sm p-0 flex items-center"
                                        onClick={() => history.push('/dashboard/knowledge-base')}
                                    >
                                        View All
                                        <ArrowRight className="ml-1 h-4 w-4" />
                                    </Button>
                                </div>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : knowledgeBases.length > 0 ? (
                                    <div>
                                        <RecentKnowledgeBaseList
                                            loading={loading}
                                            knowledgeBases={knowledgeBases}
                                            onSelect={setSelectedKnowledgeBase}
                                            selectedKnowledgeBase={selectedKnowledgeBase}
                                        />
                                    </div>
                                ) : (
                                    <p>No recent knowledge bases found.</p>
                                )}
                            </TabsContent>
                            <TabsContent value="help" className={rightActiveTab === "help" ? "visible" : "hidden"}>
                                <Separator className="mt-1" />
                                <Accordion className="mt-4" type="single" collapsible>
                                    <AccordionItem>
                                        <AccordionTrigger>What is a Knowledge Base?</AccordionTrigger>
                                        <AccordionContent>A knowledge base is a centralized repository for information, making it easier to store, manage, and retrieve knowledge.</AccordionContent>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionTrigger>How do I create a Knowledge Base?</AccordionTrigger>
                                        <AccordionContent>You can create a knowledge base by filling out the form in the 'Create Knowledge Base' tab.</AccordionContent>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionTrigger>What types of content can I add?</AccordionTrigger>
                                        <AccordionContent>You can add links, uploaded files, or text content to your knowledge base.</AccordionContent>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionTrigger>How can I access my Knowledge Bases?</AccordionTrigger>
                                        <AccordionContent>You can access your knowledge bases from the 'Recent Knowledge Bases' section.</AccordionContent>
                                    </AccordionItem>
                                </Accordion>
                            </TabsContent>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div >
    );
}
