import React from 'react';
import { Info } from "lucide-react";

import { cn } from "../../lib/utils"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './tooltip'; // Adjust the import path as necessary

export const Input = ({ label, tooltip, tooltipTrigger, required = false, error, className, ...props }) => { // Added error prop
  return (
    <div className="w-full">
      <div className="flex items-center mb-1 ">
        {label && (
          <label className="flex items-center text-xs font-semibold text-muted-foreground">
            {label}
            {required && <span className="text-red-500 ml-1">*</span>} {/* Show red * if required */}
          </label>
        )}
        {tooltip && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info className="h-3 w-3 ml-1 cursor-pointer" />
              </TooltipTrigger>
              <TooltipContent>
                <p>{tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>

      <input
        {...props}
        className={cn(
          "border rounded-md p-2 w-full bg-background",
          error ? "border-red-500" : "",
          className
        )}
      />
      {error && <p className="text-red-500 text-sm italic">{error}</p>} {/* Show error message */}
    </div>
  );
};

