import { useHistory } from 'react-router-dom';
import lodash from 'lodash';
import { Star, CreditCard } from 'lucide-react';
import { UserNav } from "../components/navbar/user-nav";
import { ModeToggle } from "./ui/mode-toggler.js";
import { NotificationDropdown } from "./ui/notification-dropdown";
import { Button } from "../components/ui/button"
import { Image } from "../components/ui/image"
import logo192 from '../images/logo192.png';
import { useRecoilState } from 'recoil';
import { userSubscriptionState, subscriptionPlansState } from '../store/store';

export const TopNav = () => {
  const history = useHistory();
  const [subscriptionState] = useRecoilState(userSubscriptionState);
  const [subscriptionPlans] = useRecoilState(subscriptionPlansState);

  const handleUpgrade = () => {
    history.push('/dashboard/billing');
  }

  const creditsUsed = lodash.get(subscriptionState, 'credits_used', 0);
  const creditsLimit = lodash.get(subscriptionState, 'credits', 0);
  const planId = lodash.get(subscriptionState, 'plan_id', null); // Get the plan_id from subscriptionState
  const activePlan = subscriptionPlans.find(plan => plan.id === planId) || {}; // Find the active plan using plan_id
  const isPaidPlan = lodash.get(activePlan, 'price', 0) > 0.0; // Check if the active plan is a paid plan

  return (
    <>
      <div className="flex-col md:flex bg-background">
        <div className="border-b">
          <div className="flex h-16 items-center pr-4">
            <div className="flex justify-between items-center  w-full">
              <Button variant='ghost' className='flex items-center' onClick={() => history.push('/')}>
                <Image className='w-8 h-8' src={logo192} />
                <p className='text-lg font-bold ml-2'>ClarityBase</p>
              </Button>
              <div className='flex items-center'>
                <span className='text-sm font-semibold bg-muted shadow-sm text-primary px-4 py-2 rounded-md ml-4 border border-primary flex justify-center items-center'>
                  <CreditCard className="mr-2 h-4 w-4" />
                  {creditsUsed} / {creditsLimit} Credits
                </span>
                {!isPaidPlan && ( // Show upgrade button only if on free plan
                  <Button onClick={handleUpgrade} className='ml-4 flex items-center'>
                    <Star className="text-yellow-500 mr-1 h-4 w-4" />
                    Upgrade
                  </Button>
                )}
                <NotificationDropdown />
                <ModeToggle />
                <UserNav />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
