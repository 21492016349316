// Custom components
import {
    Card,
    CardContent,
} from "../components/ui/card";
import LoginForm from "./LoginForm";


export const GuestUserLoginDialog = () => {

    return (
        <Card className='flex justify-center items-center h-full'>
            <CardContent className='h-full w-full flex '>
                <LoginForm />
            </CardContent>
        </Card>
    )
}
