import './App.css';
import 'animate.css';

import lodash from 'lodash';
import {
  useRecoilState
} from 'recoil';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import DashboardWrapper from './views/DashboardWrapper';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
// Custom components
import { ThemeProvider } from "../src/lib/theme-provider"
import RootPage from './views/home/RootPage';
import { checkUserSessionStatus } from './services/api';
import { userState, userSubscriptionState, subscriptionPlansState, systemConstantsState } from './store/store';
import { getSubscriptions, getSystemConstants } from './services/api';
import { logError, _initMonitoringAndLogging } from './services/logging_utils';
import { setUser } from './services/utils';
import { PoliciesPage } from './views/policies/PoliciesPage';
import { LoginPage } from './views/LoginPage';
import { SubscriptionsPage } from './views/SubscriptionsPage';
import { AdminDashboard } from './views/admin/AdminDashboard';
import HomeView from './views/dashboard/HomeView';
import SubscriptionSuccess from './views/SubscriptionSuccess'; // Import the new component
import CancelSubscription from './views/CancelSubscription'; // Import the new component

function App() {
  const history = useHistory();

  // Initialize a QueryClient instance
  const queryClient = new QueryClient();

  const [user, setUserState] = useRecoilState(userState);
  const [subscriptionState, setSubscriptionState] = useRecoilState(userSubscriptionState);
  const [subscriptionPlans, setSubscriptionPlans] = useRecoilState(subscriptionPlansState);
  const [systemConstants, setSystemConnstants] = useRecoilState(systemConstantsState);

  const _checkSessionStatus = async () => {
    try {
      const response = await checkUserSessionStatus();

      const userLoggedIn = lodash.get(response, 'data.userLoggedIn', false);
      const loggedInUser = lodash.get(response, 'data.user', false);

      // Setting the user in the local storage for persistence
      setUser(loggedInUser);

      setUserState(loggedInUser);
      setSubscriptionState(lodash.get(loggedInUser, 'subscription_details', null))

      if (userLoggedIn) {
        // Redirect the user to the Home page
        if (history) history.push('/dashboard/home')
      }
    } catch (error) {
      // Log the error here, e.g. send it to a logging service
      logError(error)
    }
  };

  const _getSubscriptionPlans = async () => {
    // Fetching the plans from the server
    try {
      const response = await getSubscriptions();
      const subscriptions = lodash.get(response, 'data', []);

      setSubscriptionPlans(subscriptions);
    } catch (error) {
      logError(error)
    }
  }

  const _getSystemConstants = async () => {
    // Fetching System constants
    try {
      const response = await getSystemConstants({ constants: "STATIC_STRINGS" });
      const constants = lodash.get(response, 'data.data', {});

      setSystemConnstants(constants);
    } catch (error) {
      logError(error)
    }
  }

  useEffect(() => {
    _checkSessionStatus();
    _getSubscriptionPlans();
    _getSystemConstants();

    // Initialising the monitoring and analytics
    _initMonitoringAndLogging();
  }, [])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <HelmetProvider>
            <div className="App">
              <Router>
                <Switch>
                  <Route exact path="/">
                    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
                      <RootPage />
                    </ThemeProvider>
                  </Route>

                  <Route path="/dashboard">
                    <DashboardWrapper
                      accounts={[]}
                      defaultLayout={[265, 655]}
                      defaultCollapsed={false}
                      navCollapsedSize={4} />
                  </Route>

                  <Route path="/admin">
                    <AdminDashboard />
                  </Route>

                  <Route exact path="/auth">
                    <LoginPage />
                  </Route>
                  <Route exact path="/subscriptions">
                    <SubscriptionsPage />
                  </Route>

                  <Route exact path="/policies">
                    <PoliciesPage />
                  </Route>
                  <Route exact path="/subscription/success" component={SubscriptionSuccess} /> {/* New route */}
                  <Route exact path="/subscription/cancel" component={CancelSubscription} /> {/* New route */}
                </Switch>
              </Router>
            </div>
          </HelmetProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
