import React, { useEffect, useState } from 'react';
import { fetchUrlContent } from '../../../services/api';  // Import the fetchUrlContent function
import YouTubePlayer from './YouTubePlayer'; // Import the new YouTubePlayer component
import { Loader2 } from 'lucide-react'; // Import the Loader2 component

const LinkPreview = ({ knowledgeBase }) => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [useFallback, setUseFallback] = useState(false);

    const { link, content_metadata } = knowledgeBase;
    const transcription = content_metadata?.transcription || [];

    useEffect(() => {
        const getContent = async () => {
            setLoading(true);
            try {
                const fetchedContent = await fetchUrlContent(link);
                setContent(fetchedContent);
            } catch (err) {
                setError(err.message);
                setUseFallback(true);
            } finally {
                setLoading(false);
            }
        };

        if (link) {
            getContent();
        }
    }, [link]);

    const handleIframeError = () => {
        setError('Failed to load the link.');
        setUseFallback(true);
    };

    if (loading) return (
        <div className="flex items-center justify-center h-full">
            <Loader2 className="animate-spin" />
        </div>
    );
    if (error) return <p>Error: {error}</p>;

    // Check if the link is a YouTube link
    const isYouTubeLink = link.includes('youtube.com') || link.includes('youtu.be');

    if (isYouTubeLink) {
        return <YouTubePlayer link={link} transcriptions={transcription} />
    }

    return (
        <div>
            {useFallback ? (
                <div>
                    <p>Loading content from the server...</p>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            ) : (
                <iframe
                    src={link}
                    style={{ width: '100%', height: '500px', border: 'none' }}
                    onError={handleIframeError}
                    title="Link Preview"
                    sandbox="allow-same-origin allow-scripts"
                />
            )}
        </div>
    );
};

export default LinkPreview;
