import React from 'react';
import KnowledgeBaseSection from './KnowledgeBaseSection';
import { Card, CardContent } from "../../../components/ui/card";

export default function KnowledgeBaseView() {
    return (
        <Card className='w-full m-2 main-area-section-h'>
            <CardContent className='h-full'>
                <KnowledgeBaseSection />
            </CardContent>
        </Card>
    );
}
