import React, { useState } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { Loader2, RefreshCcw } from 'lucide-react'; // Import the Loader2 icon from lucide-react
import { Separator } from '../../../components/ui/separator';
import { Skeleton } from '../../../components/ui/skeleton';
import { KnowledgeBaseStatusBadge } from '../../../components/KnowledgeBaseStatusBadge';
import { DetailsDialog } from '../../../components/DetailsDialog';
import { DeleteKnowledgeBaseDialog } from './DeleteKnowledgeBaseDialog';
import { EditKnowledgeBaseDialog } from './EditKnowledgeBaseDialog';
import { Button } from '../../../components/ui/button';
import { reindexKnowledgeBase } from '../../../services/api';
import { logError, logDebug } from '../../../services/logging_utils'; // Import logging utilities

export const KnowledgeBaseHeader = ({ knowledgeBase, loading, handleOnEditorUpdate, handleRefreshReport }) => {

    const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
    const [editDialogStatus, setEditDialogStatus] = useState(false);
    const [isReindexing, setIsReindexing] = useState(false); // State to track reindexing status

    const handleDeleteSuccess = () => {
        setDeleteDialogStatus(false);
        handleOnEditorUpdate(knowledgeBase);
    };

    const handleEditSuccess = () => {
        setEditDialogStatus(false);
    };

    const handleRefresh = async () => {
        setIsReindexing(true); // Set reindexing state to true
        try {
            const response = await reindexKnowledgeBase(lodash.get(knowledgeBase, ['id']));
            logDebug('Re-indexing successful:', response); // Use logDebug instead of console.log
            await handleOnEditorUpdate();
        } catch (error) {
            logError('Error re-indexing knowledge base:', error); // Use logError instead of console.error
        } finally {
            setIsReindexing(false); // Reset reindexing state
        }
    };

    if (loading) {
        return (
            <div className="flex flex-col text-left w-full">
                <div className="flex  w-full items-center px-2 py-1 justify-between">
                    <div className="flex flex-col items-start p-2 w-3/4">
                        <div className='flex w-full justify-start'>
                            <Skeleton className='w-[200px] my-1 h-[20px]' />
                        </div>
                        <Skeleton className='w-[300px] h-[15px]' />
                    </div>
                </div>
                <Separator />
            </div>
        )
    }

    const defaultDescription = 'No description was added for this knowledge base.';

    return (
        <div className="flex flex-col text-left w-full">
            <div className="flex  w-full items-center px-2 py-1 justify-between">
                <div className="flex flex-col items-start p-2 w-3/4">
                    <div className='flex w-full justify-start items-center'>
                        <h2 className="text-xl font-semibold text-ellipsis max-w-lg text-primary mr-2">{lodash.get(knowledgeBase, ['title'])}</h2>
                        <KnowledgeBaseStatusBadge status={lodash.get(knowledgeBase, ['status'])} />
                        <DetailsDialog knowledgeBase={knowledgeBase} />
                    </div>
                    <p className="text-xs w-full text-ellipsis max-w-2xl">{lodash.get(knowledgeBase, ['description']) === '' ? defaultDescription : lodash.get(knowledgeBase, ['description'])}</p>
                </div>
                <div className='flex flex-row items-center'>
                    <Button onClick={handleRefresh} size="icon" variant="outline" className="p-2 " disabled={isReindexing}>
                        {isReindexing ? <Loader2 className="animate-spin" /> : <RefreshCcw className="h-4 w-4" />} {/* Show loader icon while reindexing */}
                    </Button>
                    <DeleteKnowledgeBaseDialog
                        knowledgeBase={knowledgeBase}
                        isOpen={deleteDialogStatus}
                        setDialogStatus={setDeleteDialogStatus}
                        onDeleteSuccess={handleDeleteSuccess}
                    />
                    <EditKnowledgeBaseDialog
                        knowledgeBase={knowledgeBase}
                        isOpen={editDialogStatus}
                        setDialogStatus={setEditDialogStatus}
                        onUpdateSuccess={handleEditSuccess}
                    />
                </div>
            </div>
            <Separator />
        </div>
    )
}

KnowledgeBaseHeader.propTypes = {
    report: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    setSelectedReportVersion: PropTypes.func.isRequired,
    selectedReportVersion: PropTypes.number.isRequired,
    sanitizedLogs: PropTypes.array.isRequired,
    handleRefreshReport: PropTypes.func,
    startResearching: PropTypes.object,
};
