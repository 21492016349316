import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

// Components
import AnalyticsView from './dashboard/AnalyticsView';
import HomeView from './dashboard/HomeView';
import ReportsView from './dashboard/reports/page';
import ProductsView from './dashboard/products/page';
import { GuestUserLoginDialog } from '../components/GuestUserLoginDialog';
import ProtectedRoute from '../components/auth/ProtectedRoute';
import { TopNav } from "../components/Topnav";
import { Sidebar } from '../components/explore/sidebar';
import { getToken } from '../services/utils';
import AgentsView from './dashboard/agents/AgentsView'; // Import the AgentsView component
import KnowledgeBaseView from './dashboard/knowledge_base/KnowledgeBaseView'; // Import the KnowledgeBaseView component
import ChatView from './dashboard/products/components/ChatView'; // Import the new ChatView component
import BillingView from './dashboard/BillingView'; // Import the BillingView component

function DashboardWrapper() {

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);

    useEffect(() => {
        const token = getToken();
        if (!token) {
            setIsUserLoggedIn(false);
        }
    }, []);

    if (!isUserLoggedIn) {
        return (
            <div className="flex min-h-screen w-full flex-col bg-muted/40">
                <div className="flex flex-col sm:gap-4  h-screen">
                    <div className="col-span-3 lg:col-span-4 lg:border-l main-area-gradient h-full">
                        <div className="h-full flex-col md:flex ">
                            <GuestUserLoginDialog />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="flex min-h-screen w-full flex-col bg-muted/40">
                <div className="flex flex-col sm:gap-4  ">
                    <div className="col-span-3 lg:col-span-4 lg:border-l main-area-gradient">
                        <div className="h-full">
                            <div className="flex-col md:flex">
                                <TopNav />
                                <div className='flex'>
                                    <Sidebar className="hidden lg:block" />
                                    <Switch>
                                        <Route exact path="/dashboard/home">
                                            <HomeView />
                                        </Route>
                                        <Route exact path="/dashboard/analytics">
                                            <AnalyticsView />
                                        </Route>
                                        <ProtectedRoute path="/dashboard/products">
                                            <ProductsView />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/dashboard/reports">
                                            <ReportsView />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/dashboard/agents">
                                            <AgentsView />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/dashboard/knowledge-base">
                                            <KnowledgeBaseView scope="user_chat" />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/dashboard/chat">
                                            <ChatView />
                                        </ProtectedRoute>
                                        <ProtectedRoute path="/dashboard/billing">
                                            <BillingView />
                                        </ProtectedRoute>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardWrapper
