import axios from "axios";
import lodash from 'lodash';

import { getToken } from '../services/utils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";

export const OAUTH_PRODUCER_LOGIN_REDIRECT_ENDPOINT = `${API_BASE_URL}/auth/login-redirect`;

// Authentication APIs
export const getUserAccessToken = (params) => {
    const { authToken } = params;
    const headers = {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + authToken,
        },
        credentials: 'include',
    };
    return axios.get(`${API_BASE_URL}/auth/login`, headers);
}

export const checkUserSessionStatus = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        method: 'GET',
        credentials: 'include',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.get(`${API_BASE_URL}/auth/user-session-status`, headers);

}

export const logoutUser = async () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        credentials: 'include',
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.get(`${API_BASE_URL}/auth/logout`, headers);
}


export const getCharacters = () => {
    return axios.get(`${API_BASE_URL}/characters`);
}

export const getCharacter = (params) => {

    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const { characterId } = params;
    return axios.get(`${API_BASE_URL}/characters/${characterId}`, headers);
}

export const updateCharacter = (params) => {

    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const { payload, characterId } = params;
    return axios.put(`${API_BASE_URL}/characters/${characterId}`, { payload }, headers);
}


// Chat APIs
export const connectUserChats = () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.post(`${API_BASE_URL}/chats/user/connect`, {}, headers);
}

export const connectProductChats = (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.post(`${API_BASE_URL}/chats/product/connect`, payload, headers);
}

export const connectKnowledgeBaseChats = (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.post(`${API_BASE_URL}/chats/knowledge_base/connect`, payload, headers);
}

// New function to send a chat message to product chat
export const sendProductChatMessage = (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.post(`${API_BASE_URL}/chats/product/send`, payload, headers);
}

// New function to send a chat message to knowledge base chat
export const sendKnowledgeBaseChatMessage = (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.post(`${API_BASE_URL}/chats/knowledge_base/send`, payload, headers);
}

export const postProductChat = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const { payload } = params;
    return axios.post(`${API_BASE_URL}/chats/product/completion`, payload, headers);
}

export const testTogetherAICompletion = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    const { payload } = params;

    return axios.post(`${API_BASE_URL}/chats/togetherai/completion`, payload, headers);
}


export const clearCharacterChats = (params) => {
    const token = getToken();

    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    const { characterId } = params;
    return axios.post(`${API_BASE_URL}/chats/${characterId}/clear`, {}, headers);
}


// Subscription APIs
export const getSubscriptions = () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.get(`${API_BASE_URL}/subscription_plans`, headers);
}

// Payment APIs
export const createCheckoutSession = (params) => {
    const token = getToken();
    if (!token) { return; }

    const { body } = params;
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.post(`${API_BASE_URL}/subscriptions/stripe/create-checkout-session`, body, headers);
}

export const manageBillingSession = (params) => {
    const token = getToken();
    if (!token) { return; }

    const { body } = params;
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.post(`${API_BASE_URL}/subscriptions/stripe/manage-billing-session`, body, headers);
}

export const createSubscriptionSession = (payload) => {
    const token = getToken();
    if (!token) { return; }


    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.post(`${API_BASE_URL}/subscriptions/stripe/create-subscription-session`, payload, headers);
}


// System constant APIs
export const getSystemConstants = (params) => {
    const { constants } = params;
    return axios.get(`${API_BASE_URL}/system_constants?constants=${constants}`);
}

// Feedback APIs
export const postFeedback = (params) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }

    const { payload } = params

    return axios.post(`${API_BASE_URL}/feedbacks`, payload, headers);
}

// Product APIs
export const createProduct = ({ payload }) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.post(`${API_BASE_URL}/products`, payload, headers);
}

export const generateProductReport = ({ payload, productId }) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.post(`${API_BASE_URL}/products/${productId}/generate/reports`, payload, headers);
}

export const getProducts = () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.get(`${API_BASE_URL}/products`, headers);
}

export const getProduct = (id) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.get(`${API_BASE_URL}/products/${id}`, headers);
}

export const deleteProduct = (id) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.delete(`${API_BASE_URL}/products/${id}`, headers);
}

export const updateProductAnswers = ({ productId, payload }) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    };
    return axios.put(`${API_BASE_URL}/products/${productId}/update/answers`, payload, headers);
};

// Reports APIs
export const getReports = () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.get(`${API_BASE_URL}/reports`, headers);
}

export const getReport = (id) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.get(`${API_BASE_URL}/reports/${id}`, headers);
}

export const generateReport = (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.post(`${API_BASE_URL}/reports/${lodash.get(payload, ['report_id'])}/generate`, payload, headers);
}

export const createReport = ({ payload }) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.post(`${API_BASE_URL}/reports`, payload, headers);
}

export const updateReport = ({ payload, reportId }) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.put(`${API_BASE_URL}/reports/${reportId}`, payload, headers);
}


export const updateReportContent = ({ payload, taskId }) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.put(`${API_BASE_URL}/report_instance/${taskId}`, payload, headers);
}

export const deleteReportInstance = (instanceId) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.delete(`${API_BASE_URL}/report_instance/${instanceId}`, headers);
}


export const deleteReport = (id) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.delete(`${API_BASE_URL}/reports/${id}`, headers);
}

export const getReportTasks = (id) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.get(`${API_BASE_URL}/reports/admin/tasks`, headers);
}

export const getAdminReports = (id) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.get(`${API_BASE_URL}/reports/admin/reports`, headers);
}

// Waitlist APIs
export const addToWaitlist = (params) => {
    const { email } = params;
    return axios.post(`${API_BASE_URL}/waitlist`, { email });
}

// Analytics APIs
export const getAnalytics = () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.get(`${API_BASE_URL}/analytics`, headers);
}


// Users API
export const getUsers = () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    }
    return axios.get(`${API_BASE_URL}/users/admin`, headers);
}

// User APIs
export const getUserDetails = (userId) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    };
    return axios.get(`${API_BASE_URL}/users/${userId}`, headers); // Fetch user details
}

export const getUserReports = (userId) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    };
    return axios.get(`${API_BASE_URL}/users/${userId}/reports`, headers); // Fetch user reports
}

export const getUserProducts = (userId) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    };
    return axios.get(`${API_BASE_URL}/users/${userId}/products`, headers); // Fetch user products
}

export const getReferences = async (reportInstanceId) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
    };
    return axios.get(`${API_BASE_URL}/references/${reportInstanceId}`, headers);
}

// Scraping API
export const scrapeUrls = async (urls, userAgent = 'Mozilla/5.0', scraperType = 'default') => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    const payload = { urls, user_agent: userAgent, scraper: scraperType };
    return axios.post(`${API_BASE_URL}/scrape`, payload, headers);
}


// Chat APIs
export const getUserChats = (userId) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.get(`${API_BASE_URL}/chats/user?u_id=${userId}`, headers);
}

export const getProductChats = (productId) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.post(`${API_BASE_URL}/chats/product?product_id=${productId}`, headers);
}

export const sendChatMessage = (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };
    // Include chat_id in the payload
    return axios.post(`${API_BASE_URL}/chats/send`, payload, headers);
}

// Upload File API
export const uploadFile = async (file) => {
    const token = getToken();
    if (!token) { return; }

    const formData = new FormData();
    formData.append('file', file); // Append the file to the FormData

    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.post(`${API_BASE_URL}/knowledge_base/upload`, formData, headers);
};

export const fetchNotifications = async () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    return await axios.get(`${API_BASE_URL}/notifications`, headers);
};

export const markNotificationsSeen = async () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };
    return await axios.put(`${API_BASE_URL}/notifications/mark-seen`, {}, headers);
};

// Add this function to your existing API service
export const updateProductNote = (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };
    console.log('api', payload)
    return axios.put(`${API_BASE_URL}/product_notes/${payload.product_id}`, payload, headers);
};

// Add this function to your existing API service
export const getProductNotes = (productId) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.get(`${API_BASE_URL}/product_notes/${productId}`, headers);
};

// Knowledge Base APIs

export const deleteKnowledgeBase = (id) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };

    return axios.delete(`${API_BASE_URL}/knowledge_base/${id}`, headers);
};

// New function to fetch knowledge bases by product ID
export const getKnowledgeBases = (productId = null) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };

    let url = `${API_BASE_URL}/knowledge_base`;
    if (productId) {
        url += `/product/${productId}`;
    }
    return axios.get(url, headers);
};

// Update existing functions if needed
export const addKnowledgeBase = (data) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    return axios.post(`${API_BASE_URL}/knowledge_base`, data, headers);
};

export const updateKnowledgeBase = (id, data) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    return axios.put(`${API_BASE_URL}/knowledge_base/${id}`, data, headers);
};

// Agents APIs
export const getAgents = () => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Authorization': 'Bearer ' + token,
        },
    };
    return axios.get(`${API_BASE_URL}/agents`, headers);
};

export const addAgent = (data) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    return axios.post(`${API_BASE_URL}/agents`, data, headers);
};

export const updateAgent = (id, data) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    return axios.put(`${API_BASE_URL}/agents/${id}`, data, headers);
};

export const deleteAgent = (id) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Authorization': 'Bearer ' + token,
        },
    };
    return axios.delete(`${API_BASE_URL}/agents/${id}`, headers);
};

export const streamFile = (fileKey) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Authorization': 'Bearer ' + token,
        },
    };
    return axios.get(`${API_BASE_URL}/knowledge_base/stream/${fileKey}`, {
        ...headers,
        responseType: 'blob', // Set response type to blob for binary data
    });
};

export const generateKnowledgeBaseSummary = (knowledgeBaseId) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.post(`${API_BASE_URL}/knowledge_base/${knowledgeBaseId}/generate_summary`, {}, headers);
};

export const fetchUrlContent = async (url) => {
    const token = getToken();
    if (!token) { throw new Error('No token available'); }

    const headers = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    };

    try {
        const response = await axios.get(`${API_BASE_URL}/utils/fetch-url?url=${encodeURIComponent(url)}`, headers);
        return response.data.content;  // Return the content from the response
    } catch (error) {
        console.error("Error fetching content:", error);
        throw error;  // Rethrow the error for handling in the calling function
    }
};

// New function to get a refreshed presigned URL
export const getPresignedUrl = (fileKey) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.get(`${API_BASE_URL}/knowledge_base/presigned-url/${fileKey}`, headers);
}

export const validateCheckoutSession = async ({ session_id, user_id, plan_id }) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.post(`${API_BASE_URL}/subscriptions/validate`, { session_id, user_id, plan_id }, headers);
};

export const sendUserChatMessage = (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };
    return axios.post(`${API_BASE_URL}/chats/user/send`, payload, headers); // New endpoint for user chat
}

// New function to calculate token count for link or text knowledge base
export const calculateLinkTextCredits = async (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        },
    };

    return axios.post(`${API_BASE_URL}/knowledge_base/calculate_link_text_credits`, payload, headers);
};

// New function to calculate token count for uploaded document
export const calculateUploadedDocCredits = async (file) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Authorization': 'Bearer ' + token,
            // Remove 'Content-Type' header to let the browser set it automatically
        },
    };

    const formData = new FormData();
    formData.append('file', file); // Append the file to the FormData

    return axios.post(`${API_BASE_URL}/knowledge_base/calculate_uploaded_doc_credits`, formData, headers);
};

// New function to create a text or link knowledge base
export const createTextKnowledgeBase = async (payload) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.post(`${API_BASE_URL}/knowledge_base`, payload, headers);
};

// New function to upload a knowledge base
export const uploadKnowledgeBase = async (file) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Authorization': 'Bearer ' + token,
            // Remove 'Content-Type' header to let the browser set it automatically
        },
    };

    const formData = new FormData();
    formData.append('file', file); // Append the file to the FormData

    return axios.post(`${API_BASE_URL}/knowledge_base/upload`, formData, headers);
};

// New function to re-index a knowledge base
export const reindexKnowledgeBase = async (id) => {
    const token = getToken();
    if (!token) { return; }

    const headers = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
    };

    return axios.post(`${API_BASE_URL}/knowledge_base/${id}/reindex`, {}, headers);
};













