import React from 'react';
import { Loader2 } from 'lucide-react';
import { Separator } from '../components/ui/separator';
import KnowledgeBaseCard from './KnowledgeBaseCard';

const RecentKnowledgeBaseList = ({ loading, knowledgeBases, onSelect, selectedKnowledgeBase }) => {
    return (
        <div className="rounded-lg h-full flex flex-col items-center justify-center overflow-y-auto " style={{ maxHeight: 'calc(100vh - 264px)' }}>
            {loading ? (
                <div className="flex flex-col items-center text-primary">
                    <Loader2 className="animate-spin h-6 w-6 " />
                    <p className="text-sm text-muted-foreground">Loading Knowledge bases...</p>
                </div>
            ) : knowledgeBases.length > 0 ? (
                knowledgeBases.slice(0, 5).map(kb => (
                    <KnowledgeBaseCard
                        key={kb.id}
                        knowledgeBase={kb}
                        onSelect={() => onSelect(kb)} // Call onSelect with the knowledge base object
                        selected={selectedKnowledgeBase?.id === kb.id}
                    />
                ))
            ) : (
                <p className='text-sm text-muted-foreground'>No recent knowledge bases found.</p>
            )}
        </div>
    );
};

export default RecentKnowledgeBaseList;
