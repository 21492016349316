import { Button } from "../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import LoginForm from "../components/LoginForm"

export function LoginDialog({ btnLabel }) {
  return (
    <Dialog className="w-full h-full p-0">
      <DialogTrigger asChild>
        <Button size='lg' className='text-lg font-bold'>{btnLabel}</Button>
      </DialogTrigger>
      <DialogContent className="w-full h-full">
        <LoginForm />
      </DialogContent>
    </Dialog>
  )
}
