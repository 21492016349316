import lodash from 'lodash';
import { Button } from "../../../../components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogClose,
} from "../../../../components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../components/ui/tabs";
import { Separator } from "../../../../components/ui/separator";
import { Alert } from "../../../../components/ui/alert";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import { useState } from 'react';
import { KnowledgeBaseForm } from "../../../../components/KnowledgeBaseForm"; // Import the new KnowledgeBaseForm component

export function AddKnowledgeBaseDialog(props) {
    const { renderActionTrigger, isOpen, onClose, product, onUpdate } = props;

    if (!isOpen) {
        return renderActionTrigger();
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose} className="w-screen max-w-4xl animate__animated animate__fadeIn">
            <DialogTrigger asChild>
                {renderActionTrigger()}
            </DialogTrigger>
            <DialogContent className="gap-0 flex flex-col justify-start" style={{ minHeight: '80vh', maxHeight: '80%', overflowY: 'auto' }}>
                <div className='flex flex-col gap-0'>
                    <h2 className="text-xl font-bold mb-0">Add New Knowledge Base Entry</h2>
                    <p className="text-sm">Fill in the details below to create a new entry in your knowledge base by providing a link, uploading a file, or entering text information.</p>
                </div>
                <Separator className="my-2" />
                <KnowledgeBaseForm product={product} onUpdate={onUpdate} /> {/* Use the KnowledgeBaseForm component */}
            </DialogContent>
        </Dialog>
    )
}
