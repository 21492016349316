import lodash from 'lodash';
import { useState } from 'react'; // Removed useRecoilState import
import { Trash2, Loader, AreaChart } from 'lucide-react';

// Components
import { Sheet, SheetTrigger, SheetContent } from "./ui/sheet";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { Separator } from "./ui/separator";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "./ui/tooltip";
import { StatusBadge } from '../views/dashboard/products/components/StatusBadge';

// Services
import { userState } from '../store/store';
import { deleteReportInstance } from '../services/api';

export const ResearchReportVersionList = ({ report, selectedReportVersion, setSelectedReportVersion, user }) => { // Added user as a prop
    const [confirmDelete, setConfirmDelete] = useState(null); // Add state for confirmation
    const [loading, setLoading] = useState(false); // Add state for loading

    const items = typeof lodash.get(report, ['content']) === 'string' ? [] : lodash.get(report, ['content'], []);

    if (items.length === 0) {
        return null;
    }

    const dateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };

    const handleReportVersionDelete = async (version) => {
        if (confirmDelete === version) {
            setLoading(true); // Show loader
            try {
                const reportInstanceId = lodash.get(report, ['content', version, 'id']);
                console.log('reportInstanceId', reportInstanceId, version);
                const response = await deleteReportInstance(reportInstanceId);
                // Handle successful deletion (e.g., update state)
                setConfirmDelete(null); // Reset confirmation
            } catch (error) {
                // Handle error
            } finally {
                setLoading(false); // Hide loader
            }
        } else {
            setConfirmDelete(version); // Set confirmation for the selected version
        }
    }

    const calculateTimeDifference = (startTime, endTime) => {
        const start = new Date(startTime);
        const end = new Date(endTime);
        return (end - start) / 1000; // Convert milliseconds to seconds
    }

    return (
        <>
            <div className="flex-col md:flex">
                <div className="">
                    <div className="flex items-center">
                        <Sheet>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <SheetTrigger asChild>
                                            <Button size="icon" variant="outline" className='relative' >
                                                <AreaChart className="h-4 w-4" />
                                                <span className='border rounded-lg p-1 bg-secondary mx-1 font-bold absolute h-6 w-6 flex justify-center items-center' style={{ top: "-0.75rem", right: "-0.75rem", borderRadius: '50%' }}>{items.length}</span>
                                            </Button>
                                        </SheetTrigger>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>Previously generated reports</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                            <SheetContent side="right" className="p-4 ">
                                <div className='rounded p-2 h-full'>
                                    <div className='flex justify-start items-center mb-2'>
                                        <h1 className='text-lg font-bold  mr-4'>Report versions</h1>
                                        <Badge>{items.length}</Badge>
                                    </div>

                                    <Separator />

                                    <div className='pt-2 h-full'>
                                        <div className="flex flex-col gap-2 pt-0 " style={{ maxHeight: 'calc(100vh - 96px)', overflowY: 'auto' }}>
                                            {/* <AnimatedList> */}
                                            {(items || []).map((item, index) => (
                                                <TooltipProvider key={item.id}>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <button
                                                                key={item.id}
                                                                className={`flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent animate-slide-down ${selectedReportVersion === index ? 'bg-accent' : ''}`}
                                                                onClick={() => setSelectedReportVersion(index)}
                                                            >
                                                                <div className="flex items-center gap-2 w-full">
                                                                    <div className="font-semibold  max-w-full">
                                                                        <p className={`${lodash.get(item, ['task']) ? '' : 'text-ellipsis'} font-bold`}>{index + 1}. {lodash.get(item, ['task']) || lodash.get(item, ['content'], '')}</p>
                                                                        <p className='text-xs'> Created at: {new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(lodash.get(item, ['created_at'])))}</p>
                                                                        <StatusBadge status={lodash.get(item, ['status'])} />
                                                                        {lodash.get(user, ['role']) === 'admin' ? (
                                                                            <div className='flex flex-col'>
                                                                                <div className='flex flex-col w-full justify-start my-1'>
                                                                                    <p className='text-xs font-semibold'> Fast Model: {lodash.get(item, ['fast_llm_model'])}</p>
                                                                                    <p className='text-xs font-semibold'> Smart Model: {lodash.get(item, ['smart_llm_model'])}</p>
                                                                                </div>

                                                                                <div className='flex flex-col w-full justify-start my-1'>
                                                                                    <p className='text-xs font-semibold'> Start Time: {lodash.get(item, ['start_time'])}</p>
                                                                                    <p className='text-xs font-semibold'> End Time: {lodash.get(item, ['end_time'])}</p>
                                                                                    <p className='text-xs font-semibold'> Generation time: {calculateTimeDifference(lodash.get(item, ['start_time']), lodash.get(item, ['end_time'])).toFixed(2)} seconds</p>
                                                                                </div>

                                                                            </div>
                                                                        ) : null}
                                                                        <Button size="icon" variant="outline" className='mx-1' onClick={() => handleReportVersionDelete(index)} >
                                                                            <Trash2 className="h-4 w-4" />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </TooltipTrigger>
                                                        {/* <TooltipContent>
                                                            <p>{lodash.get(item, ['content'])}</p>
                                                        </TooltipContent> */}
                                                    </Tooltip>
                                                </TooltipProvider>
                                            ))}
                                            {/* </AnimatedList> */}
                                        </div>
                                    </div>
                                </div>
                            </SheetContent>
                        </Sheet>
                    </div>
                </div>
            </div >
        </>
    );
}
