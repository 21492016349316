import React, { useEffect, useState } from "react";
import { useRecoilState } from 'recoil';
import { Check, Sparkles, Loader2 } from 'lucide-react';
import lodash from 'lodash';
// Components
import { Button } from "../../components/ui/button";
import { Switch } from "../../components/ui/switch";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../components/ui/tabs";
import { subscriptionPlansState, userSubscriptionState } from '../../store/store';
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "../../components/ui/card";
import { Separator } from "../../components/ui/separator";
import ErrorBoundary from '../../components/ui/ErrorBoundary'; // Import ErrorBoundary
// Services
import { getSubscriptions, createSubscriptionSession } from '../../services/api';
import { logError } from '../../services/logging_utils';

const BillingView = () => {
    const [activeTab, setActiveTab] = useState("current"); // State for active tab
    const [subscriptions, setSubscriptions] = useRecoilState(subscriptionPlansState);
    const [userSubscription] = useRecoilState(userSubscriptionState);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [interval, setInterval] = useState('monthly');
    const [sessionLoadingPlanId, setSessionLoadingPlanId] = useState(null); // Track the plan currently loading session

    const fetchSubscriptions = async () => {
        try {
            const response = await getSubscriptions();
            setSubscriptions(response.data);
        } catch (err) {
            setError('Failed to load subscription plans.');
            logError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (subscriptions.length === 0) { // Only fetch if subscriptions are not available
            fetchSubscriptions().catch(err => {
                logError(err);
            });
        } else {
            setLoading(false); // Set loading to false if subscriptions are already available
        }
    }, [subscriptions]);

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen w-full">
                <Loader2 className="animate-spin w-8 h-8 text-primary" />
                <span className="mt-2">Loading...</span>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    const highestPricePlan = Math.max(...subscriptions.map(plan => lodash.get(plan, 'price'))); // Determine the highest price plan

    // New logic to check if the user is on the highest plan
    const isOnHighestPlan = userSubscription && userSubscription.plan_id &&
        subscriptions.some(plan => lodash.get(plan, 'id') === userSubscription.plan_id && lodash.get(plan, 'price') === highestPricePlan);

    if (isOnHighestPlan) {
        const activePlan = subscriptions.find(plan => lodash.get(plan, 'id') === userSubscription.plan_id);
        return (
            <div className="flex min-h-screen w-full flex-col px-2 py-2 text-left overflow-y-auto bg-muted">
                <div className="bg-background rounded-lg h-full flex flex-col justify-start items-start p-4">
                    <div className="flex flex-col justify-center items-center w-full">
                        <div className="plan-card border-primary w-full border rounded-lg p-4 text-center">
                            <h3 className="text-xl font-semibold text-primary">{lodash.get(activePlan, 'name')}</h3>
                            <p className="text-md">{`${lodash.get(activePlan, 'currency')} ${lodash.get(activePlan, 'price')} ${interval === 'yearly' ? '/ year' : '/ month'}`}</p>
                            <Button
                                size="lg"
                                className="text-lg mt-4 w-full"
                                onClick={() => setActiveTab('plans')}
                            >
                                View Other Plans
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const filteredSubscriptions = subscriptions.filter(plan => lodash.get(plan, 'recurrence') === interval);
    const recommendedPlan = filteredSubscriptions.find(plan => lodash.get(plan, 'recommended'));
    const sortedSubscriptions = filteredSubscriptions
        .filter(plan => !lodash.get(plan, 'recommended'))
        .sort((a, b) => lodash.get(a, 'price') - lodash.get(b, 'price'));

    const centerIndex = Math.floor(sortedSubscriptions.length / 2);
    if (recommendedPlan) {
        sortedSubscriptions.splice(centerIndex, 0, recommendedPlan);
    }

    const handleSubscribe = async (plan) => {
        try {
            const options = { plan };
            setSessionLoadingPlanId(lodash.get(plan, 'id')); // Set the plan ID that is currently loading session
            const sessionId = await getSubscriptionSession(options);
            setSessionLoadingPlanId(null); // Reset loading plan ID

            if (sessionId) {
                const stripePublishableKey = 'pk_test_51QAvtID3EHTrDREk37WPCtMfUjKCwpbym70otJaMZJ3cDnGLMjbaH7X9Ng46vhkE1SjjQWsamh4mWxDizqzD6ses001pUIvymx';
                const stripe = window.Stripe(stripePublishableKey);
                await stripe.redirectToCheckout({ sessionId });
            }
        } catch (error) {
            setSessionLoadingPlanId(null); // Reset loading plan ID on error
            logError(`Error during subscription process: ${error.message}`);
        }
    };

    const getSubscriptionSession = async (options) => {
        try {
            const response = await createSubscriptionSession(options);
            const session = lodash.get(response, ['data']);
            return lodash.get(session, ['id']);
        } catch (err) {
            logError(err);
            return null;
        }
    };

    const activePlan = subscriptions.find(plan => lodash.get(plan, 'id') === userSubscription.plan_id);
    const isOnPaidPlan = activePlan && lodash.get(activePlan, 'price') > 0;

    return (
        <ErrorBoundary> {/* Wrap the return statement with ErrorBoundary */}
            <div className="flex min-h-screen w-full flex-col px-2 py-2 text-left overflow-y-auto bg-muted" style={{ maxHeight: 'calc(100vh - 64px)', height: 'calc(100vh - 64px)' }} >
                <div className="bg-background rounded-lg h-full flex flex-col justify-start items-start p-4">
                    <Tabs defaultValue={activeTab} className="w-full">
                        <TabsList>
                            <TabsTrigger active={activeTab === "current"} value="current" onClick={() => setActiveTab("current")}>Current Plan</TabsTrigger>
                            <TabsTrigger active={activeTab === "plans"} value="plans" onClick={() => setActiveTab("plans")}>All Plans</TabsTrigger>
                        </TabsList>

                        <TabsContent value="current" className={activeTab === "current" ? "visible" : "hidden"}>
                            <Separator className="my-4" />
                            {isOnPaidPlan ? (
                                <div className="plan-card border-primary border rounded-lg p-4 text-center w-full sm:w-1/3 mx-auto">
                                    <h3 className="text-2xl font-semibold">{lodash.get(activePlan, 'name')}</h3>
                                    <p className="text-md font-semibold text-primary">{`${lodash.get(activePlan, 'currency')} ${lodash.get(activePlan, 'price')} ${interval === 'yearly' ? '/ year' : '/ month'}`}</p>
                                    <Separator className="my-2" />
                                    <ul className="text-left">
                                        {lodash.get(activePlan, 'features') && lodash.get(activePlan, 'features').map((feature, index) => (
                                            <li key={index} className="text-sm text-muted-foreground flex items-center my-1">
                                                <Check className="text-green-500 mr-2 w-4 h-4" />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <Button
                                        size="lg"
                                        className="text-lg mt-4 w-full"
                                        onClick={() => setActiveTab('plans')}
                                    >
                                        View Other Plans
                                    </Button>
                                </div>
                            ) : (
                                <div>No active paid plan.</div>
                            )}
                        </TabsContent>
                        <TabsContent value="plans" className={activeTab === "plans" ? "visible" : "hidden"}>

                            <Separator className="my-4" />
                            <div className="flex flex-col items-center w-full">
                                <div className="flex flex-col items-center">
                                    <div className="flex justify-center items-center py-4">
                                        <span className="mr-2">Monthly</span>
                                        <Switch checked={interval === 'yearly'} onCheckedChange={() => setInterval(interval === 'monthly' ? 'yearly' : 'monthly')} />
                                        <span className="ml-2">Yearly</span>
                                    </div>
                                    <div className="flex space-x-2 justify-center">
                                        {sortedSubscriptions.map(plan => {
                                            const isActivePlan = userSubscription && userSubscription.plan_id === lodash.get(plan, 'id');
                                            const isLowerPricePlan = isActivePlan ? false : (userSubscription && userSubscription.plan_id && lodash.get(plan, 'price') < lodash.get(subscriptions.find(p => lodash.get(p, 'id') === userSubscription.plan_id), 'price', Infinity));

                                            return (
                                                <div className="flex flex-col justify-center items-center w-full" key={lodash.get(plan, '_id')}>
                                                    {isActivePlan && (
                                                        <span className="text-sm text-primary font-bold p-1 rounded bg-primary/10">Active Plan</span>
                                                    )}
                                                    <div
                                                        className={`plan-card ${lodash.get(plan, 'recommended') ? 'border-primary' : ''} ${isActivePlan ? 'border-primary' : ''} w-full border rounded-lg p-4 text-center cursor-pointer transition duration-300`}
                                                    >
                                                        <h3 className="text-2xl font-semibold ">{lodash.get(plan, 'name')}</h3>
                                                        <p className="text-md font-semibold text-primary">
                                                            {`${lodash.get(plan, 'currency')}${lodash.get(plan, 'price')} ${interval === 'yearly' ? '/ year' : '/ month'}`}
                                                        </p>
                                                        <Button
                                                            size="lg"
                                                            className="text-lg transition-transform duration-300 transform hover:scale-105 mt-4 w-full"
                                                            onClick={() => handleSubscribe(plan)}
                                                            disabled={isActivePlan || isLowerPricePlan || sessionLoadingPlanId !== null && sessionLoadingPlanId !== lodash.get(plan, 'id')} // Disable button if session is loading for another plan
                                                        >
                                                            {isActivePlan ? 'Subscribed' : sessionLoadingPlanId === lodash.get(plan, 'id') ? <Loader2 className="animate-spin w-4 h-4" /> : 'Subscribe'}
                                                        </Button>
                                                        <div>
                                                            <Separator className="my-2" />
                                                            <div className="flex justify-start items-center p-1 my-2 rounded-sm animate-pulse">
                                                                <Sparkles className="w-4 h-4 mr-2 text-yellow-500" />
                                                                <p className="text-md font-semibold"><span className="text-primary font-bold">{lodash.get(plan, 'credits')} </span>credits</p>
                                                            </div>
                                                            <Separator className="my-2" />
                                                            <ul className="text-left">
                                                                {lodash.get(plan, 'features') && lodash.get(plan, 'features').map((feature, index) => (
                                                                    <li key={index} className="text-sm text-muted-foreground flex items-center my-1">
                                                                        <Check className="text-green-500 mr-2 w-4 h-4" />
                                                                        {feature}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </TabsContent>
                    </Tabs>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default BillingView;
