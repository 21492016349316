import React from 'react';
import ErrorBoundary from '../../../components/ui/ErrorBoundary'; // Import the ErrorBoundary component

const YouTubePlayer = ({ link, transcriptions }) => {
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`; // Format as mm:ss
    };

    const handleTranscriptionClick = (startTime) => {
        const iframe = document.getElementById('youtube-player');
        if (iframe) {
            iframe.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'seekTo',
                args: [startTime, true] // args is an array with [seconds, allowSeekAhead]
            }), '*');
        }
    };

    // Group transcriptions based on length
    const groupedTranscriptions = [];
    let currentGroup = [];

    (transcriptions || []).forEach((transcription) => {
        const [startTime, endTime] = transcription.timestamp.split(' - ');
        const duration = parseFloat(endTime) - parseFloat(startTime);

        if (duration >= 5 && duration <= 30) {
            currentGroup.push(transcription);
        } else {
            if (currentGroup.length > 0) {
                groupedTranscriptions.push(currentGroup);
                currentGroup = [];
            }
        }
    });

    // Push the last group if it exists
    if (currentGroup.length > 0) {
        groupedTranscriptions.push(currentGroup);
    }

    return (
        <ErrorBoundary> {/* Wrap the component with ErrorBoundary */}
            <div className="">
                <div className="youtube-preview">
                    <iframe
                        id="youtube-player"
                        width="100%" // Make the video full width
                        height="380" // Set the video height to 70%
                        src={`https://www.youtube.com/embed/${link.split('v=')[1] || ''}?enablejsapi=1`}  // Enable JS API for YouTube
                        title="YouTube Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
                <div className="transcriptions product-view_tab-content" style={{ marginTop: '20px' }}>
                    {groupedTranscriptions.map((group, groupIndex) => (
                        <div key={groupIndex}>
                            {group.map((transcription, index) => {
                                const [startTime, endTime] = transcription.timestamp.split(' - ');
                                const roundedStartTime = Math.floor(parseFloat(startTime));
                                return (
                                    <p key={index} className="text-sm p-2 rounded-sm bg-gray-100 my-1 cursor-pointer hover:shadow-lg" onClick={() => handleTranscriptionClick(roundedStartTime)}>
                                        <strong>{`[${formatTime(parseFloat(startTime))} - ${formatTime(parseFloat(endTime))}] `}</strong>: {transcription.text}
                                    </p>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default YouTubePlayer;
