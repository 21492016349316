import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/react";
// import posthog from 'posthog-js'

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || 'd807a1fd15b2377f39737c85609f179c';
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "https://432303be3caef3ed0eb985c301b30222@o4507125208186880.ingest.us.sentry.io/4507232823083008";

export const logError = (error) => {
    // Log the error here, e.g. send it to a logging service
    console.error('Error:', JSON.stringify(error));
    Sentry.captureException(error);
};

export const logDebug = (message) => {
    // Log the debug message here, e.g. send it to a logging service
    console.log('Debug:', message);
};

export const _initMonitoringAndLogging = () => {
    // Initializing Mixpanel
    mixpanel.init(MIXPANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage', opt_out_tracking_by_default: false });

    trackAnalyticsEvent("APP_MOUNTED");

    // Init Sentry
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            // Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
    });
};

export const trackAnalyticsEvent = (eventName, eventProperties) => {
    mixpanel.track(eventName, eventProperties);
};