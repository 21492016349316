import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription } from './ui/card'; // Adjust the import path as necessary
import { KnowledgeBaseStatusBadge } from './KnowledgeBaseStatusBadge'; // Adjust the import path as necessary
import lodash from 'lodash';

const KnowledgeBaseCard = ({ knowledgeBase, onSelect, selected }) => {
    return (
        <Card
            className={`flex flex-col items-start border my-2 p-2 rounded-lg w-full cursor-pointer ${selected ? 'bg-primary text-white' : 'bg-background text-foreground'} ${selected ? '' : 'hover:bg-muted hover:shadow-lg'}`}
            onClick={() => onSelect(knowledgeBase)}
        >
            <CardHeader className="p-2 flex flex-col items-start text-left">
                <CardTitle className={`font-semibold text-lg mb-0 overflow-hidden text-ellipsis whitespace-nowrap max-w-full ${selected ? 'text-white' : ''}`}>{lodash.get(knowledgeBase, 'title')}</CardTitle>
                <CardDescription className={`flex w-full text-xs whitespace-wrap overflow-hidden max-w-full ${selected ? 'text-white' : 'text-muted-foreground'}`}>{lodash.get(knowledgeBase, 'description') || 'No description available.'}</CardDescription>
                <div className="flex">
                    <KnowledgeBaseStatusBadge status={lodash.get(knowledgeBase, 'status')} />
                </div>
            </CardHeader>
        </Card>
    );
};

export default KnowledgeBaseCard;
