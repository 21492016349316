import lodash from 'lodash';
import { Info } from 'lucide-react';
import { Button } from "./ui/button";
import { useRecoilState } from 'recoil';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "./ui/dialog";
import { Card, CardContent, CardHeader } from './ui/card'; // Import Card components
import { Separator } from './ui/separator';

import { systemConstantsState } from '../store/store';

export function LatestReleasesDialog() {

    const [systemConstants] = useRecoilState(systemConstantsState);
    const releaseNotes = lodash.get(systemConstants, ['STATIC_STRINGS', 'value', 'RELEASE_NOTES'], []);

    if (releaseNotes.length === 0) return null;

    return (
        <Dialog className="w-screen max-w-4xl">
            <DialogTrigger asChild>
                <Button variant="ghost" size="icon" className="text-md flex h-8 w-8 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8">
                    <Info className='h-4 w-4 transition-all group-hover:scale-110 ' />
                </Button>
            </DialogTrigger>
            <DialogContent className="p-4">
                <h2 className="text-lg font-bold mb-4">Latest Releases</h2>
                {releaseNotes.map((release, index) => (
                    <Card key={index} className="mb-4 ">
                        <CardHeader className='bg-blue-100 p-4 py-2'>
                            <h3 className="text-md font-semibold mb-0">Version: {release.version}</h3>
                            <p className="text-xs text-gray-500">Release Date: {release.date}</p>
                        </CardHeader>
                        <Separator />
                        <CardContent className='p-4 '>
                            <ol className=""> {/* Changed to ordered list */}
                                {release.features.map((feature, i) => (
                                    <li key={i} className='text-sm'>{feature}</li>
                                ))}
                            </ol>
                        </CardContent>
                    </Card>
                ))}
            </DialogContent>
        </Dialog>
    )
}