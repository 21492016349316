import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { generateKnowledgeBaseSummary } from '../services/api'; // Import the renamed API function
import { logDebug, logError } from '../services/logging_utils';
import ReactMarkdown from 'react-markdown'; // Import ReactMarkdown for rendering markdown

const SummaryEditor = ({ knowledgeBase }) => {
    const isSummaryEmpty = !knowledgeBase.summary; // Check if summary is empty

    const handleGenerateSummary = async () => {
        try {
            await generateKnowledgeBaseSummary(knowledgeBase.id); // Call the API to generate summary
            // Optionally, you can refresh the knowledgeBase data here
        } catch (error) {
            logError("Error generating summary: " + error); // Use logError for error logging
        }
    };

    return (
        <>
            {isSummaryEmpty ? (
                <div className="text-center h-full flex flex-col justify-center items-center">
                    <h2 className="font-bold">No Summary Generated</h2>
                    <p>You can generate the summary for the knowledge base by clicking the button below.</p>
                    <Button
                        onClick={async () => {
                            logDebug("Generating summary for knowledge base ID: " + knowledgeBase.id);
                            try {
                                await handleGenerateSummary();
                                logDebug("Summary generated successfully for knowledge base ID: " + knowledgeBase.id);
                            } catch (error) {
                                logError("Failed to generate summary for knowledge base ID: " + knowledgeBase.id + " - " + error);
                            }
                        }}
                    >
                        Generate Summary
                    </Button>
                </div>
            ) : (
                <div className="markdown-summary m-2 ">
                    <ReactMarkdown>{knowledgeBase.summary}</ReactMarkdown> {/* Render the summary as markdown */}
                </div>
            )}
        </>
    );
};

SummaryEditor.propTypes = {
    knowledgeBase: PropTypes.object.isRequired,
};

export default SummaryEditor;