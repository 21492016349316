import lodash from 'lodash';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { useState } from 'react';
import { Separator } from "./ui/separator";
import { Badge } from "./ui/badge"; // Import Badge component
import { FaLink, FaYoutube, FaFilePowerpoint, FaFilePdf } from 'react-icons/fa'; // Import icons

import { createTextKnowledgeBase, uploadKnowledgeBase, calculateUploadedDocCredits, calculateLinkTextCredits, reindexKnowledgeBase } from '../services/api'; // Update import
import { UploadComponent } from "./ui/upload";
import { trackAnalyticsEvent } from '../services/logging_utils';
import { logError, logDebug } from '../services/logging_utils'; // Import logError and logDebug
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { useRecoilState } from 'recoil';
import { userState, userSubscriptionState } from '../store/store'; // Import user and userSubscription states
import ErrorBoundary from './ui/ErrorBoundary'; // Import ErrorBoundary

export function KnowledgeBaseForm({ product, onUpdate }) {
    const [selectedTab, setSelectedTab] = useState('link');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [urlInput, setUrlInput] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [textInput, setTextInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [urlInputError, setUrlInputError] = useState('');
    const [textInputError, setTextInputError] = useState('');
    const [uploadedFileError, setUploadedFileError] = useState('');
    const [creditCount, setCreditCount] = useState(null); // State to hold credit count
    const [calculatingCredits, setCalculatingCredits] = useState(false); // State to show loading message
    const [showConfirmation, setShowConfirmation] = useState(false); // State to manage confirmation dialog

    const [user, setUser] = useRecoilState(userState); // Get user state
    const [userSubscription, setUserSubscription] = useRecoilState(userSubscriptionState); // Get user subscription state

    const referenceDetails = [
        { type: 'Web', icon: <FaLink />, urlPattern: /^(http|https):\/\/(?!www\.youtube\.com|youtu\.be)[^ "]+$/, key: 'web' },
        { type: 'Youtube', icon: <FaYoutube />, urlPattern: /^(http|https):\/\/(www\.)?(youtube\.com\/.*|youtu\.be\/.*)/, key: 'youtube' },
        { type: 'PPT links', icon: <FaFilePowerpoint />, urlPattern: /\.pptx?$/, key: 'ppt' },
        { type: 'PDF links', icon: <FaFilePdf />, urlPattern: /\.pdf$/, key: 'pdf' },
    ];

    const handleCreditCalculation = async (data, file) => {
        setCalculatingCredits(true); // Show loader
        try {
            let creditCountResponse;
            if (data.type === 'upload') {
                creditCountResponse = await calculateUploadedDocCredits(file); // Call the new upload credit calculation API
            } else {
                creditCountResponse = await calculateLinkTextCredits(data); // Call the new link/text credit calculation API
            }
            const creditCountValue = lodash.get(creditCountResponse, 'data.credits_used');
            setCreditCount(creditCountValue); // Update credit count state
            console.log(`Credit count for the knowledge base: ${creditCountValue}`);
            return creditCountValue;
        } catch (error) {
            setError('Error calculating credit count');
            logError('Error calculating credit count:', error);
        } finally {
            setCalculatingCredits(false); // Hide loader
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError('');
        setTitleError('');
        setDescriptionError('');
        setUrlInputError('');
        setTextInputError('');
        setUploadedFileError('');

        let hasError = false;

        if (selectedTab === 'link') {
            if (!urlInput.trim()) {
                setUrlInputError('Please enter a valid URL.');
                hasError = true;
            } else {
                const isValidUrl = referenceDetails.some(detail => detail.urlPattern.test(urlInput.trim()));
                if (!isValidUrl) {
                    setUrlInputError('The URL provided is not valid for the selected type.');
                    hasError = true;
                } else {
                    setUrlInputError(''); // Clear error if URL is valid
                }
            }
        } else if (selectedTab === 'upload' && !uploadedFile) {
            setUploadedFileError('Please upload a file.');
            hasError = true;
        } else if (selectedTab === 'text' && !textInput.trim()) {
            setTextInputError('Please enter your text.');
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        try {
            let fileUrl = '';

            if (selectedTab === 'upload' && uploadedFile) {
                setIsUploading(true);
                const uploadResponse = await uploadKnowledgeBase(uploadedFile); // Call the new upload API
                fileUrl = lodash.get(uploadResponse, ['data', 'url']);
                setIsUploading(false);
            }

            let link;
            if (selectedTab === 'link') {
                link = urlInput;
                // Credit calculation is now handled in handleAddKnowledgeBase
            } else if (selectedTab === 'upload') {
                link = fileUrl;
            } else {
                link = textInput;
                // Credit calculation is now handled in handleAddKnowledgeBase
            }

            let data = {
                title: title,
                description: description,
                product_id: lodash.get(product, 'id'),
                link: link,
                type: selectedTab,
                content: selectedTab === 'text' ? textInput : undefined, // Only include content for text type
            };

            // Proceed with adding the knowledge base
            const response = await createTextKnowledgeBase(data); // Call the new create API
            const addedKnowledgeBase = lodash.get(response, ['data', 'knowledge_base']);
            const updatedUserSubscription = lodash.get(response, ['data', 'user_subscription']);

            // Update user and userSubscription states
            setUserSubscription(updatedUserSubscription);

            logDebug('Knowledge base document added successfully!'); // Use logDebug

            trackAnalyticsEvent("KNOWLEDGE_BASE_ENTRY_ADDED", { title, description, type: selectedTab });

            onUpdate(addedKnowledgeBase);
        } catch (error) {
            setError(error.message);
            logError('Error submitting knowledge base document:', error); // Use logError
        } finally {
            setLoading(false);
        }
    };

    const handleAddKnowledgeBase = async () => {
        setLoading(true); // Show loader on button
        setError(''); // Clear previous errors

        // Calculate credit count
        const creditCountValue = await handleCreditCalculation({
            title,
            description,
            product_id: lodash.get(product, 'id'),
            type: selectedTab,
            link: urlInput || textInput || (uploadedFile ? uploadedFile : undefined) // Ensure uploadedFile is passed correctly
        }, uploadedFile); // Pass uploadedFile to the calculation function

        if (creditCountValue > 2) {
            setShowConfirmation(true); // Show confirmation dialog if credit count exceeds 100
        } else {
            await handleSubmit(); // Proceed with submission if credit count is within limit
        }

        setLoading(false); // Hide loader after processing
    };

    const handleTabChange = (value) => {
        setSelectedTab(value);
        setShowConfirmation(false); // Clear confirmation message state
        setCreditCount(null); // Reset credit count
    };

    return (
        <ErrorBoundary>
            <div className='flex flex-col w-full'>
                <Tabs className='w-full' defaultValue="link" value={selectedTab} onValueChange={handleTabChange}>
                    <TabsList className='grid grid-cols-3 w-full'>
                        <TabsTrigger active={selectedTab === 'link'} value="link">Link</TabsTrigger>
                        <TabsTrigger active={selectedTab === 'upload'} value="upload">Upload File</TabsTrigger>
                        <TabsTrigger active={selectedTab === 'text'} value="text">Text Entry</TabsTrigger>
                    </TabsList>
                    <TabsContent value="link">
                        <div className="flex flex-col gap-2 my-2">
                            <Input
                                label="Knowledge Base URL"
                                tooltip="Provide a valid URL related to the knowledge base entry."
                                value={urlInput}
                                onChange={(e) => {
                                    setUrlInput(e.target.value);
                                    setUrlInputError(''); // Clear error on change
                                    setShowConfirmation(false); // Clear confirmation message state
                                    setCreditCount(null); // Reset credit count
                                }}
                                disabled={loading || isUploading}
                                required={selectedTab === 'link'}
                                error={urlInputError}
                            />
                            {/* Render badges based on the reference details */}
                            <div className="flex flex-col flex-wrap gap-2 mt-2">
                                <p className="text-sm text-gray-500">Supported link types:</p>

                                <div className="flex flex-row">
                                    {referenceDetails.map((detail, index) => {
                                        const isMatch = detail.urlPattern.test(urlInput.trim());
                                        return (
                                            <Badge
                                                className='text-sm thin mr-2'
                                                key={index}
                                                variant={isMatch ? 'default' : 'secondary'} // Only highlight the matching one
                                            >
                                                <span className="mr-1">{detail.icon}</span> {detail.type}
                                            </Badge>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </TabsContent>
                    <TabsContent value="upload">
                        <div className="flex flex-col gap-2 my-2">
                            <p className="block text-xs font-semibold text-muted-foreground">Please upload a file that contains information for the knowledge base entry.</p>
                            <UploadComponent
                                productId={lodash.get(product, 'id')}
                                onChange={(file) => {
                                    setUploadedFile(file);
                                    if (file) {
                                        setUploadedFileError('');
                                        setShowConfirmation(false); // Clear confirmation message state
                                        setCreditCount(null); // Reset credit count
                                    }
                                }}
                                disabled={loading || isUploading}
                                loading={isUploading}
                                error={uploadedFileError}
                                tooltip="Upload files in .pdf, .docx, or .txt formats."
                            />
                        </div>
                    </TabsContent>
                    <TabsContent value="text">
                        <div className="flex flex-col gap-2 my-2">
                            <Textarea
                                label="Knowledge Base Text"
                                tooltip="Enter detailed text information for the knowledge base entry."
                                value={textInput}
                                onChange={(e) => {
                                    setTextInput(e.target.value);
                                    setShowConfirmation(false); // Clear confirmation message state
                                    setCreditCount(null); // Reset credit count
                                }}
                                disabled={loading || isUploading}
                                required={selectedTab === 'text'}
                                error={textInputError}
                            />
                        </div>
                    </TabsContent>
                </Tabs>

                <Separator className="my-2" />
                <p className="text-lg font-semibold text-primary mb-2">Reference details</p>
                <div className="flex flex-col mb-4">
                    <Input
                        label="Entry Title"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            setShowConfirmation(false); // Clear confirmation message state
                            setCreditCount(null); // Reset credit count
                        }}
                        error={titleError}
                    />
                    <p className="text-xs text-muted-foreground">To generate AI-generated title, leave the input empty. The title will be updated based on the content of the knowledge base.</p>
                </div>
                {!showConfirmation && (
                    <Button size="2xl" className='text-lg w-auto' onClick={handleAddKnowledgeBase} disabled={loading || isUploading}>
                        {calculatingCredits ? 'Estimating Credits...' : (loading ? 'Submitting...' : 'Add Knowledge Base')}
                    </Button>
                )}
                {error && <div className='text-red-500'>{error}</div>}
                {showConfirmation && (
                    <div className="confirmation-dialog p-4 rounded-md bg-muted">
                        <p className='text-sm mb-2'>To add this knowledge base, <span className='text-primary font-bold'>{creditCount}</span> credits will be utilized. Would you like to proceed?</p>
                        <div className="flex gap-2">
                            <Button onClick={() => handleSubmit()}>Yes, let's add</Button>
                            <Button variant="secondary" onClick={() => setShowConfirmation(false)}>No, cancel</Button>
                        </div>
                    </div>
                )}
            </div>
        </ErrorBoundary>
    );
}
